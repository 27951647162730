@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    --page-padding: 0 6.25em;
    --sectioning-gap: 3.65625em 0;
    --flex-gap: 3em;
    --card-padding: 2em;
    --nav-height: 4em;
}

@media screen and (min-width: 1727px) {
    html {
        --nav-height: 7em;
    }
}

@media screen and (max-width: 1024px) {
    html {
        --page-padding: 0 3.25em;
    }
}

@media screen and (max-width: 767px) {
    html {
        --page-padding: 0 2.0625em;
    }
}

@media (max-width: 425px) {
    html {
        --sectioning-gap: 1.5em 0;
    }
}

@media screen and (max-width: 360px) {
    html {
        --page-padding: 0 0.5em;
    }
}