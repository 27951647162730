#Home .Header-LF h1,
#Home .Header-LF h2 {
    font-family: 'Montserrat';
    font-size: 55px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: 0em;
    color: #FFFFFF;
    margin: 0;
}

#Home .Header-LF #Body {
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    color: #FFFFFF;
}

#Home .Hero-Tab .slide-in {
    opacity: 0;
    animation: slide-in-from-left 1s ease-out forwards;
    will-change: transform;
    backface-visibility: hidden;
}

@keyframes slide-in-from-left {
    0% {
        opacity: 0;
        transform: translateX(-150%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

#Home .Hero-Tab {
    margin: var(--sectioning-gap);
}

#Home .Hero-Tab .Text-Card h6 {
    font-family: 'Montserrat';
    font-size: 32px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

#Home .Hero-Tab .Body-Text {
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
}

#Home .Other-Financial-Services {
    margin: var(--sectioning-gap);
    padding: var(--page-padding);
    background: #007CB4;
}

#Home .Other-Financial-Services .Image-Box {
    border-radius: 24px;
    overflow: hidden;
}

#Home .Other-Financial-Services .Image-Box img {
    height: 100%;
    object-fit: cover;
}

#Home .Other-Financial-Services #Image-Text-Box {
    margin: 0;
}

#Home .Other-Financial-Services #Image-Text-Box .Flex-Box .Text-Card:nth-child(1) .Text-Container h6 {
    font-family: "Montserrat";
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    color: #FFFFFF;
    margin-top: 0;
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card .Text-Container .Body-Text {
    display: none;
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box {
    gap: var(--card-padding);
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box {
    padding: var(--card-padding);
    background: #F4F4F4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    border: 1px solid #FFFFFF;
    cursor: pointer;
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box:hover {
    background: none;
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box:hover h6,
#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box:hover p {
    color: #FFFFFF;
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box:hover img {
    filter: brightness(0) invert(1);
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box h6 {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
}

#Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box p {
    font-family: "Manrope";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

#Home .Contact-Us {
    margin: var(--page-padding);
    background: #F6F6F6;
    border: 1px solid #DBE4E9;
    border-radius: 20px;
}

#Home .Contact-Us .slide-in {
    opacity: 0;
    animation: slide-in-right 1s ease-out forwards;
    will-change: transform;
    backface-visibility: hidden;
}

@keyframes slide-in-right {
    0% {
        opacity: 0;
        transform: translate3d(150%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

#Home .Contact-Us #Image-Text-Box {
    padding: 0;
}

#Home .Contact-Us .Text-Card {
    padding: 3em;
}

#Home .Contact-Us .Text-Card h6 {
    font-family: 'Montserrat';
    font-size: 55px;
    font-weight: 700;
    line-height: 63px;
    letter-spacing: 0em;
}

#Home .Contact-Us .Body-Text {
    font-family: 'Montserrat';
    font-size: 23px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    color: #757575;
}

#Home .Contact-Us button {
    padding-top: 10em;
}

#Home .Getting-Started,
#Home .Info-Container {
    margin: var(--page-padding);
    padding: var(--sectioning-gap);
}

#Home .Getting-Started .Text-Cards {
    display: flex;
    justify-content: space-between;
    gap: var(--flex-gap);
}

#Home .Getting-Started h4 {
    font-family: 'Montserrat';
    font-size: 55px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;
}

#Home .Getting-Started .Text-Card h6 {
    font-family: 'Montserrat';
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.03em;
    text-align: center;
}

#Home .Getting-Started .Text-Card .Body-Text {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: center;
}

#Home .Getting-Started .Text-Card {
    text-align: center;
    padding: var(--card-padding);
}

#Home .Getting-Started .Text-Card button {
    margin: auto;
}

#Home .Getting-Started .Text-Card button p {
    color: #000000;
}

#Home .Info-Container .Text-Cards {
    display: flex;
    justify-content: space-between;
    gap: var(--flex-gap);
}

#Home .Info-Container .Text-Card:hover {
    background: #007CB4;
    color: #FFFFFF;
}

#Home .Info-Container .Text-Card:hover .Icon-Container img {
    filter: brightness(0) invert(1);
}

#Home .Info-Container .Text-Card {
    padding: var(--card-padding);
}

#Home .Info-Container .Text-Card h6 {
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: -0.03em;
}

#Home .Info-Container .Text-Card p {
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0em;
}

#Home .Info-Container h3 {
    font-family: 'Montserrat';
    font-size: 55px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-block-end: var(--card-padding);
}

#Home .Mail-Box-Container {
    margin: var(--sectioning-gap);
}

@media screen and (max-width: 1727px) {
    #Home .Hero-Tab .Text-Card h6 {
        font-size: 28px;
        line-height: 35px;
    }

    #Home .Hero-Tab .Body-Text {
        font-size: 14px;
    }

    #Home .Header-LF h1,
    #Home .Header-LF h2 {
        font-size: 45px;
        line-height: 60px;
    }

    #Home .Contact-Us .Text-Card h6 {
        font-size: 45px;
        line-height: 55px;
    }

    #Home .Contact-Us .Body-Text {
        font-size: 17px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1727px) {
    #Home .Info-Container .Text-Card h6 {
        font-size: 18px;
        line-height: 32px;
    }

    #Home .Info-Container .Text-Card p {
        font-size: 17px;
        line-height: 28px;
    }
}

@media screen and (max-width: 1440px) {
    #Home .Hero-Tab .Flex-Box {
        flex-direction: column-reverse;
    }

    #Home .Contact-Us .Text-Card {
        padding: 0;
    }

    #Home .Contact-Us button {
        padding: 0;
    }

    #Home .Contact-Us #Image-Text-Box {
        gap: 0;
    }

    #Home .Info-Container h3 {
        font-size: 35px;
        line-height: 45px;
    }

}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    #Home .Getting-Started .Text-Card h6 {
        font-size: 20px;
    }

    #Home .Getting-Started .Text-Card .Body-Text {
        font-size: 12px;
        line-height: 22px;
    }

    #Home .Getting-Started .Text-Card {
        padding: 1em;
    }
}

@media screen and (max-width: 1280px) {
    #Home .Header-RF {
        display: none;
    }

    #Home .Info-Container .Text-Cards,
    #Home .Getting-Started .Text-Cards {
        flex-direction: column;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    #Home .Hero-Tab .Flex-Box .Text-Card-Box {
        flex-direction: row;
    }
}

@media screen and (max-width: 1024px) {

    #Home .Header-LF h1,
    #Home .Header-LF h2 {
        font-size: 40px;
        line-height: 60px;
    }

    #Home .Header-LF #Body {
        font-size: 17px;
    }

    #Home .Getting-Started h4 {
        font-size: 40px;
    }

    #Home .Info-Container h3 {
        width: 75%;
    }

    #Home .Contact-Us .Text-Card h6 {
        font-size: 30px;
        line-height: 35px;
    }

    #Home .Hero-Tab {
        margin: 0;
    }

    #Home .Other-Financial-Services .Image-Box {
        overflow: unset;
    }
}

@media screen and (max-width: 693px) {
    #Home .Info-Container h3 {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    #Home .Header-LF .Btn-Container button {
        width: 80%;
    }

    #Home .Header {
        align-items: stretch;
    }

    #Home .Header-LF h1,
    #Home .Header-LF h2 {
        font-size: 30px;
        line-height: 40px;
    }

    #Home .Header-LF #Body {
        font-size: 16px;
        line-height: 23px;
    }

    #Home .Hero-Tab .Text-Card h6 {
        font-size: 18px;
        line-height: 24px;
    }

    #Home .Hero-Tab .Body-Text {
        font-size: 12px;
        line-height: 20px;
    }

    #Home .Contact-Us .Text-Card h6,
    #Home .Other-Financial-Services #Image-Text-Box .Flex-Box .Text-Card:nth-child(1) .Text-Container h6 {
        font-size: 25px;
        line-height: 30px;
    }

    #Home .Contact-Us .Body-Text {
        font-size: 12px;
        line-height: 20px;
    }

    #Home .Getting-Started h4 {
        font-size: 30px;
        line-height: 41px;
    }

    #Home .Getting-Started .Text-Card h6 {
        font-size: 23px;
    }

    #Home .Getting-Started .Text-Card .Body-Text {
        font-size: 12px;
        line-height: 22px;
    }

    #Home .Info-Container h3 {
        font-size: 20px;
        line-height: 28px;
    }

    #Home .Info-Container .Text-Card h6 {
        font-size: 20px;
        line-height: 32px;
    }

    #Home .Info-Container .Text-Card p {
        font-size: 17px;
        line-height: 28px;
    }
}

@media screen and (max-width: 280px) {

    #Home .Header-LF h1,
    #Home .Header-LF h2 {
        font-size: 18px;
        line-height: 28px;
    }

    #Home .Header-LF #Body {
        font-size: 12px;
        line-height: 20px;
    }

    #Home .Hero-Tab .Text-Card h6,
    #Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box h6 {
        font-size: 14px;
        line-height: 22px;
    }

    #Home .Hero-Tab .Body-Text,
    #Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box p {
        font-size: 10px;
    }

    #Home .Hero-Tab .Icon-Container img {
        width: 20%;
    }

    #Home .Contact-Us .Text-Card h6,
    #Home .Other-Financial-Services #Image-Text-Box .Flex-Box .Text-Card:nth-child(1) .Text-Container h6 {
        font-size: 18px;
        line-height: 25px;
    }

    #Home .Getting-Started h4 {
        font-size: 15px;
        line-height: 20px;
    }

    #Home .Getting-Started .Text-Card {
        padding: 1em 0;
    }

    #Home .Getting-Started .Text-Card h6 {
        font-size: 16px;
        line-height: 25px;
    }

    #Home .Getting-Started .Text-Card .Icon-Container img {
        width: 20%;
    }

    #Home .Info-Container h3 {
        font-size: 13.5px;
        line-height: 20px;
    }

    #Home .Info-Container .Text-Card {
        padding: 1em 0;
    }

    #Home .Info-Container .Text-Card h6 {
        font-size: 14px;
        line-height: 24px;
    }

    #Home .Info-Container .Text-Card p {
        font-size: 12px;
        line-height: 20px;
    }

    #Home .Info-Container .Text-Card .Icon-Container img {
        width: 20%;
    }

    #Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box {
        padding: 5%;
    }
}

@media screen and (max-width: 200px) {

    #Home .Header-LF h1,
    #Home .Header-LF h2 {
        font-size: 10px;
        line-height: 18px;
        word-break: break-all;
    }

    #Home .Header-LF #Body {
        font-size: 8px;
        line-height: 14px;
        word-break: break-all;
    }

    #Home .Contact-Us .Text-Card h6 {
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
    }

    #Home .Contact-Us .Body-Text {
        font-size: 8px;
        line-height: 15px;
        word-break: break-all;
    }

    #Home .Info-Container h3 {
        font-size: 13.5px;
        line-height: 20px;
        word-break: break-all;
    }
}

@media screen and (max-width: 150px) {

    #Home .Hero-Tab .Text-Card h6,
    #Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box h6 {
        font-size: 10px;
        line-height: 18px;
        word-break: break-all;
    }

    #Home .Hero-Tab .Body-Text,
    #Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box p {
        font-size: 8px;
        line-height: 15px;
        word-break: break-all;
    }

    #Home .Contact-Us .Text-Card h6,
    #Home .Other-Financial-Services #Image-Text-Box .Flex-Box .Text-Card:nth-child(1) .Text-Container h6 {
        font-size: 10px;
        line-height: 15px;
        word-break: break-all;
    }

    #Home .Contact-Us #Image-Text-Box {
        margin: 0;
    }

    #Home .Getting-Started h4 {
        font-size: 8px;
        line-height: 15px;
        word-break: break-all;
    }

    #Home .Getting-Started .Text-Card h6 {
        font-size: 12px;
        line-height: 20px;
        word-break: break-all;
    }

    #Home .Getting-Started .Text-Card .Body-Text {
        font-size: 8px;
        line-height: 14px;
        word-break: break-all;
    }

    #Home .Info-Container h3 {
        font-size: 10px;
        line-height: 15px;
        word-break: break-all;
    }

    #Home .Info-Container .Text-Card h6 {
        font-size: 10px;
        line-height: 18px;
        word-break: break-all;
    }

    #Home .Info-Container .Text-Card p {
        font-size: 8px;
        line-height: 15px;
        word-break: break-all;
    }

    #Home .Other-Financial-Services #Image-Text-Box .Text-Card-Box .Utility-Box {
        gap: 5%;
    }
}