#About .Header-LF h2 {
    font-family: 'Montserrat';
    font-size: 64px;
    font-weight: 500;
    line-height: 87px;
    letter-spacing: 0em;
    color: #FFFFFF;
    margin: 0;
}

#About .Header-LF h1 {
    font-family: 'Montserrat';
    font-size: 64px;
    font-weight: 700;
    line-height: 87px;
    letter-spacing: 0em;
    color: #FFFFFF;
    margin: 0;
}

#About .Header-LF #Body {
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    color: #FFFFFF;
}

#About .Animated-Hero {
    margin: var(--page-padding);
}

.DisplayNone {
    display: none !important;
}

#About .Hero-Tab {
    margin: var(--sectioning-gap);
}

#About .Hero-Tab #Image-Text-Box {
    background: #F6F6F6;
    border-radius: 38px;
}

#About .Hero-Tab #Image-Text-Box .Flex-Box {
    padding: var(--page-padding);
    align-items: center;
}

#About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box {
    gap: 0;
}

#About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box h6 {
    font-family: 'Montserrat';
    font-size: 55px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

#About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box .Body-Text {
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
}

#About .Invest-Section .Icon-Container {
    display: none;
}

#About .Invest-Section .Text-Container h6 {
    font-size: 3.4375rem;
    font-family: "Montserrat";
    font-weight: 700;
    line-height: 115%;
    margin: 0;
}

#About .Invest-Section .Text-Container .Body-Text {
    color: #757575;
    font-size: 1.4375rem;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 141.5%;
}

#About .Invest-Section .Image-Box img {
    object-fit: cover;
    height: 100%;
}

#About .Invest-Section .Text-Card .Utility #Button-Text {
    color: #FFFFFF;
}

#About .Invest-Section .slide-in {
    opacity: 0;
    animation: slide-in-from-left 1s ease-out forwards;
    will-change: transform;
    backface-visibility: hidden;
}

#About .Invest-Section .Text-Card .Utility {
    padding: var(--sectioning-gap);
}

@keyframes slide-in-from-left {
    0% {
        opacity: 0;
        transform: translateX(-150%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

#About .Profile-Section {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: var(--flex-gap);
    padding: var(--sectioning-gap);
    margin: var(--page-padding);
}

#About .Profile-Section .Text-Card {
    flex: 1;
}

#About .Profile-Section .Text-Card .Icon-Container img {
    width: 100%;
}

#About .Profile-Section .Text-Card h6 {
    font-family: 'Montserrat';
    font-size: 35px;
    font-weight: 600;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

#About .Profile-Section .Text-Card .Body-Text {
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #757575;
}

#About .slide-in {
    opacity: 0;
    animation: slide-in-right 1s ease-out forwards;
    will-change: transform;
    backface-visibility: hidden;
}

@keyframes slide-in-right {
    0% {
        opacity: 0;
        transform: translate3d(150%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

#About .Testimony-Section .Text-Card h6 {
    font-family: 'Montserrat';
    font-size: 65px;
    font-weight: 600;
    line-height: 79px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    margin-block-start: 2em;
}

#About .Testimony-Section .Text-Card .Body-Text {
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
}

#About .Testimony-Section .Text-Card .Utility {
    margin-top: var(--flex-gap);
}

#About .Testimony-Section .Text-Card .Utility #Button-Text {
    color: #FFFFFF;
}

#About .Calendly-Box {
    margin: var(--sectioning-gap);
}

@media screen and (max-width: 1728px) {

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box h6,
    #About .Invest-Section .Text-Container h6 {
        font-size: 30px;
        line-height: 40px;
    }

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box .Body-Text {
        font-size: 14px;
        line-height: 30px;
    }

    #About .Profile-Section .Text-Card h6 {
        font-size: 25px;
        line-height: 35px;
    }

    #About .Profile-Section .Text-Card .Body-Text {
        font-size: 14px;
        line-height: 22px;
    }

    #About .Testimony-Section .Text-Card h6 {
        font-size: 37px;
        line-height: 50px;
    }

    #About .Testimony-Section .Text-Card .Body-Text {
        font-size: 18px;
        line-height: 30px;
    }
}

@media screen and (max-width: 1440px) {
    #About .Invest-Section .Text-Container .Body-Text {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1280px) {
    .Hero-Tab .Flex-Box {
        flex-direction: column-reverse;
    }

    #About .Header-LF h2 {
        font-size: 40px;
        line-height: 60px;
    }

    #About .Header-LF h1 {
        font-size: 40px;
        line-height: 60px;
    }

    #About .Header-LF #Body {
        font-size: 17px;
        line-height: 23px;
    }

    .Btn-Container {
        margin: var(--sectioning-gap);
    }

    #About .Testimony-Section .Text-Card h6 {
        margin-block-start: 0;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
    #About .Testimony-Section .Flex-Box {
        align-items: center;
    }
}

@media screen and (max-width: 1024px) {
    #About .Profile-Section {
        flex-direction: column;
        align-items: stretch;
    }

    #About .Profile-Section .Text-Card .Body-Text {
        font-size: 16px;
        line-height: 25px;
        text-align: center;
    }

    #About .Profile-Section .Text-Card h6 {
        text-align: center;
    }

    .Profile-Section .Text-Card {
        align-items: center;
    }

    .Testimony-Section .Flex-Box {
        flex-direction: column-reverse;
    }

    #About .Hero-Tab #Image-Text-Box {
        border-radius: 18px;
    }

    #About .Invest-Section .Text-Card .Utility {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    #About .Header-RF {
        display: none;
    }
}

@media screen and (max-width: 500px) {

    #About .Header-LF h1,
    #About .Header-LF h2 {
        font-size: 30px;
        line-height: 41px;
    }

    #About .Header {
        align-items: stretch;
    }

    #About .Header-LF #Body {
        font-size: 14px;
        line-height: 23px;
    }

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box h6 {
        font-size: 18px;
        line-height: 25px;
    }

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box .Body-Text {
        font-size: 12px;
        line-height: 25px;
    }

    #About .Profile-Section .Text-Card h6 {
        font-size: 20px;
        line-height: 27px;
        text-align: center;
    }

    #About .Profile-Section .Text-Card .Body-Text {
        font-size: 15px;
        line-height: 25px;
        text-align: center;
    }

    #About .Testimony-Section .Text-Card h6,
    #About .Invest-Section .Text-Container h6 {
        font-size: 25px;
        line-height: 30px;
    }

    #About .Testimony-Section .Text-Card .Body-Text,
    #About .Invest-Section .Text-Container .Body-Text {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 360px) {
    #About .Profile-Section .Text-Card h6 {
        font-size: 18px;
        line-height: 24px;
    }

    #About .Profile-Section .Text-Card .Body-Text {
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (max-width: 280px) {

    #About .Header-LF h1,
    #About .Header-LF h2 {
        font-size: 18px;
        line-height: 28px;
    }

    #About .Header-LF #Body {
        font-size: 12px;
        line-height: 20px;
    }

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box h6,
    #About .Invest-Section .Text-Container h6 {
        font-size: 10px;
        line-height: 15px;
        word-break: break-all;
    }

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box .Body-Text,
    #About .Invest-Section .Text-Container .Body-Text {
        font-size: 8px;
        line-height: 12px;
        word-break: break-all;
    }

    #About .Testimony-Section .Text-Card h6 {
        font-size: 16px;
        line-height: 25px;
    }

    #About .Testimony-Section .Text-Card .Body-Text {
        font-size: 12px;
        line-height: 18px;
    }
}

@media screen and (max-width: 200px) {

    #About .Header-LF h1,
    #About .Header-LF h2 {
        font-size: 10px;
        line-height: 18px;
        word-break: break-all;
    }

    #About .Header-LF #Body {
        font-size: 8px;
        line-height: 14px;
        word-break: break-all;
    }

    #About .Profile-Section .Text-Card h6 {
        word-break: break-all;
    }

    #About .Profile-Section .Text-Card .Body-Text {
        word-break: break-all;
    }
}

@media screen and (max-width: 150px) {

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box h6,
    #About .Invest-Section .Text-Container h6 {
        font-size: 8px;
        line-height: 12px;
    }

    #About .Hero-Tab #Image-Text-Box .Flex-Box .Text-Card-Box .Body-Text {
        font-size: 5px;
        line-height: 7px;
    }

    #About .Hero-Tab #Image-Text-Box .Flex-Box {
        padding: 0 2px;
    }

    #About .Hero-Tab #Image-Text-Box {
        border-radius: 5px;
    }

    #About .Profile-Section .Text-Card h6 {
        font-size: 8px;
        line-height: 12px;
    }

    #About .Profile-Section .Text-Card .Body-Text {
        font-size: 5px;
        line-height: 7px;
    }

    #About .Testimony-Section .Text-Card h6 {
        font-size: 10px;
        line-height: 16px;
        word-break: break-all;
    }

    #About .Testimony-Section .Text-Card .Body-Text {
        font-size: 8px;
        line-height: 14px;
        word-break: break-all;
    }
}